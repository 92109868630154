import React from 'react';
import './Slicer.css';


export default function Slicer() {
    return (
        <div className="dash-wrapper">
            <h2>Привет, это Chocolate Fiesta Cloud</h2>
        </div>
    );
}
